@import 'import';
.draggable {
  position: relative;
  width: 100%;
}

.row {
  flex-wrap: nowrap;
  width: 100%;
  margin: 27px auto 0;

  @include no-scrollbar();

  @include media-breakpoint-up(xl) {
    margin: 32px auto 0;
  }
}

.container {
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--card-gap, 18px);

  width: fit-content;
  margin: 0 auto;
  padding: 3px var(--padding-x, 20px);

  @include media-breakpoint-down(md) {
    column-gap: var(--card-gap, 16px);
  }
}
